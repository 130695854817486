/* General Layout */
.done-orders-page-od {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    min-height: 100vh;
    background: none;
    overflow: hidden;
    /* Prevent overall page scroll */
}

/* Styling for <strong> elements with the order-info class */
.order-details strong.order-info {
    font-size: 16px !important;
    /* Normal font size */
    color: #333 !important;
    /* Normal text color */
    padding: 4px 8px !important;
    /* Add padding to make it stand out */
    border-radius: 5px !important;
    /* Rounded corners */
    background-color: #f4f7f9 !important;
    /* Background similar to product list */
    font-weight: normal !important;
    /* Ensure text is not bold */
    display: inline-block !important;
    /* Display inline */
    line-height: 1.6 !important;
    /* Ensure line height matches */
}

/* Scrollable Container */
.orders-container-od {
    width: 100%;
    max-width: 1200px;
    height: 80vh;
    /* Set height for scrollable area */
    overflow-y: auto;
    /* Enable vertical scrolling */
    padding: 20px;
    background-color: #B3E5FC;
    border-radius: 8px;

}

/* Orders Section */
.orders-container-od h2 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
}

/* Orders Grid - Explicit 3 Columns */
.orders-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding-bottom: 20px;
    /* Add padding at the bottom for spacing */
}

/* Order Card Styling */
.order-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.order-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 1024px) {
    .orders-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .orders-grid {
        grid-template-columns: 1fr;
    }
}
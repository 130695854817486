body {
    font-family: 'Poppins', sans-serif;
    background: #b3e5fc;
    margin: 0;
}

.profile-modern-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    margin-top: -100px;
}

.profile-modern-content {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    width: 100%;
}

.profile-modern-card {
    background: white;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
    width: 100%;
    max-width: 450px;
    transition: transform 0.3s ease;
}

.profile-modern-card:hover {
    transform: translateY(-10px);
}

.profile-modern-card-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.profile-modern-info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.profile-modern-info-item {
    background: #f1f5f9;
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.profile-modern-info-label {
    font-weight: 600;
    color: #007bff;
    margin-bottom: 5px;
}

.profile-modern-password-reset {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-modern-input {
    padding: 15px;
    margin-bottom: 20px;
    width: 80%;
    border-radius: 8px;
    border: 1px solid #ddd;
    outline: none;
    transition: border-color 0.3s ease;
}

.profile-modern-input:focus {
    border-color: #007bff;
}

.profile-modern-button {
    padding: 15px 30px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.profile-modern-button:hover {
    background-color: #0056b3;
}

.profile-modern-message {
    margin-top: 20px;
    color: green;
    font-size: 16px;
}

.profile-modern-loading {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-top: 100px;
}

/* Updated styles for logout button */
.profile-modern-logout-section {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    /* Spacing to separate it from the other info */
}

.profile-modern-logout-button {
    background-color: #e74c3c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.profile-modern-logout-button:hover {
    background-color: #c0392b;
}
body {
    padding-top: 80px;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    background-color: #f4f7f9;
}

/* Navbar wrapper styling */
.navbar-wrapper {
    background-color: #56ccf2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Navbar styling */
.navbar {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Navbar header styling */
.navbar-header h1 {
    font-size: 24px;
    color: white;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Navbar links styling */
.navbar-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

/* Navbar link styling */
.navbar-links .navbar-link {
    text-decoration: none;
    color: white;
    font-weight: bold;
    transition: color 0.3s ease;
    margin-left: 20px;
    /* Add space between links */
}

.navbar-links .navbar-link:hover {
    color: #2f80ed;
    /* Hover color for links */
}

/* Additional spacing for Đơn Hoàn Thành and Hồ Sơ */
@media (min-width: 769px) {
    .navbar-links .navbar-link:first-of-type {
        margin-left: 0;
        /* Reset left margin for the first link */
        margin-right: 30px;
        /* Add spacing to the right of the first link */
    }
}

/* Dropdown button styling */
.dropdown-button {
    background-color: #2d9cdb;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    position: relative;
}

.dropdown-button:hover {
    background-color: #2f80ed;
}

/* Arrow styling */
.arrow {
    display: inline-block;
    margin-left: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    padding: 5px;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
}

/* Arrow pointing up when dropdown is open */
.arrow-up {
    transform: rotate(-135deg);
}

/* Dropdown content container */
.dropdown-content {
    position: absolute;
    background-color: white;
    min-width: 200px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 1;
    margin-top: 10px;
    right: 0;
    text-align: right;
}

/* Dropdown links */
.dropdown-content a {
    color: #333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-content a:hover {
    background-color: #f0f4f8;
    color: #2f80ed;
}

/* Dropdown container styling */
.navbar-dropdown {
    position: relative;
    display: inline-block;
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .navbar {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .navbar-header {
        margin-left: 20px;
        /* Add space to the left of the header */
    }

    .navbar-links {
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        /* Ensure links take full width */
    }

    .navbar-links .navbar-link {
        display: none;
        /* Hide direct links on mobile */
    }

    .navbar-dropdown {
        display: block;
        /* Show dropdown on mobile */
    }

    .dropdown-content {
        position: absolute;
        right: 10px;
        /* Position dropdown to the right side */
        left: auto;
        text-align: right;
        width: auto;
        min-width: 200px;
    }

    .dropdown-button {
        margin-right: 20px;
        /* Add some space to the right of the dropdown button */
    }
}